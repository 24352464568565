<template>
  <div class="container">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h2>Emails para avaliação</h2>
    <table class="table">
      <tr>
        <th>Transportadora</th>
        <th>Dias após entrega para enviar email</th>
        <th>Dias totais de entrega</th>
      </tr>
      <tr v-for="day in days" :key="day.id">
        <td>{{ day.shipping_company }}</td>
        <td>
          <input
            type="number"
            v-model="day.days"
            @change="updateDays(day.id, day.days)"
          />
        </td>
        <td>
          <input
            type="number"
            v-model="day.days_to_delivered"
            @change="updateDelivered(day.id, day.days_to_delivered)"
          />
        </td>
      </tr>
    </table>
    <hr />
    <div style="text-align: center">
      <a id="openArticle" class="btn btn-primary" v-b-modal.modal-4
        >Adicionar Email</a
      >
    </div>
    <table class="table">
      <tr>
        <th>Nome</th>
        <th>Email</th>
        <th>Data Começo</th>
        <th>Data Término</th>
        <th>Editar</th>
        <th>Remover</th>
      </tr>

      <tr v-for="email in emails" :key="email.id">
        <td>{{ email.name }}</td>
        <td>{{ email.email }}</td>
        <td>{{ splitDate(email.date_start) }}</td>
        <td>{{ splitDate(email.date_end) }}</td>
        <td>
          <button
            class="btn btn-success"
            @click="
              openEmail(
                email.id,
                email.date_start,
                email.date_end,
                email.email,
                email.name,
                email.email_portugues,
                email.email_spanish,
                email.email_japanese
              )
            "
          >
            Editar
          </button>
        </td>
        <td>
          <button class="btn btn-danger" @click="deleteEmail(email.id)">
            Remover
          </button>
        </td>
      </tr>
    </table>
    <b-modal
      ref="modal_add"
      id="modal-4"
      title="Adiconar Email"
      size="lg"
      hide-footer
    >
      <form v-on:submit.prevent="addEmail()">
        <div class="form-group">
          <label for="nameEmail">Nome</label>
          <input
            type="text"
            class="form-control"
            id="nameEmail"
            v-model="name"
            required
          />
        </div>
        <div class="form-group">
          <label for="dateStart">Data para Começar</label>
          <input
            type="date"
            class="form-control"
            id="dateStart"
            v-model="dateStart"
            required
          />
        </div>
        <div class="form-group">
          <label for="dateEnd">Data para Acabar</label>
          <input
            type="date"
            class="form-control"
            id="dateEnd"
            v-model="dateEnd"
            required
          />
        </div>
        <h6>Inglês</h6>
        <vue-editor v-model="email"> </vue-editor>
        <br />
        <h6>Português</h6>
        <vue-editor v-model="emailPortugues"></vue-editor>
        <br />
        <h6>Espanhol</h6>
        <vue-editor v-model="emailSpanish"></vue-editor>
        <br />
        <h6>Japonês</h6>
        <vue-editor v-model="emailJapanese"></vue-editor>
        <button type="submit" class="btn btn-primary float-right">
          Adicionar Email
        </button>
      </form>
    </b-modal>
    <b-modal
      ref="modal_edit"
      id="modal-3"
      title="Editar Email"
      size="lg"
      hide-footer
    >
      <form v-on:submit.prevent="editEmail()">
        <div class="form-group">
          <label for="nameEmail">Nome</label>
          <input
            type="text"
            class="form-control"
            id="nameEmail"
            v-model="nameEdit"
          />
        </div>
        <div class="form-group">
          <label for="dateStart">Data para Começar</label>
          <input
            type="date"
            class="form-control"
            id="dateStart"
            v-model="dateStartEdit"
          />
        </div>
        <div class="form-group">
          <label for="dateEnd">Data para Acabar</label>
          <input
            type="date"
            class="form-control"
            id="dateEnd"
            v-model="dateEndEdit"
            required
          />
        </div>
        <h6>Inglês</h6>
        <vue-editor v-model="emailEdit"> </vue-editor>
        <br />
        <h6>Português</h6>
        <vue-editor v-model="emailPortuguesEdit"></vue-editor>
        <br />
        <h6>Espanhol</h6>
        <vue-editor v-model="emailSpanishEdit"></vue-editor>
        <br />
        <h6>Japonês</h6>
        <vue-editor v-model="emailJapaneseEdit"></vue-editor>
        <button type="submit" class="btn btn-primary float-right">
          Editar Email
        </button>
      </form>
    </b-modal>
    <!-- <button class="btn btn-primary float-right" @click="editEmail()">
      Guardar Email
    </button> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
// Basic Use - Covers most scenarios
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    Loading,
  },
  data: function () {
    return {
      days: [],
      email: "",
      emailPortugues: "",
      emailSpanish: "",
      emailJapanese: "",
      name: "",
      dateStart: "",
      dateEnd: "",
      loading: false,
      emails: [],
      emailEdit: "",
      dateStartEdit: "",
      dateEndEdit: "",
      nameEdit: "",
      idEdit: "",
      emailPortuguesEdit: "",
      emailSpanishEdit: "",
      emailJapaneseEdit: "",
    };
  },
  methods: {
    async getDays() {
      this.loading = true;
      try {
        await this.$store.dispatch("daysToEvaluation");
        await this.$store.dispatch("getEmailMarketing", {
          id: 1,
        });
        console.log(this.getEmail);
        this.emails = this.getEmail;
        this.days = this.getDaysToEvaluation;
      } catch (err) {
        console.log(err);
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async updateDays(id, newDays) {
      try {
        await this.$store.dispatch("updateDaysToEvaluation", {
          id: id,
          newDays: newDays,
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
      console.log(id, newDays);
    },
    async updateDelivered(id, newDays) {
      try {
        await this.$store.dispatch("updateDaysToDelivered", {
          id: id,
          newDays: newDays,
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
      console.log(id, newDays);
    },
    async editEmail() {
      try {
        await this.$store.dispatch("updateEmailMarketing", {
          id: this.idEdit,
          email: this.emailEdit,
          dateStart: this.dateStartEdit,
          dateEnd: this.dateEndEdit,
          name: this.nameEdit,
          emailPortugues: this.emailPortuguesEdit,
          emailSpanish: this.emailSpanishEdit,
          emailJapanese: this.emailJapaneseEdit,
        });
        await this.getDays();
        this.$refs["modal_edit"].hide();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async addEmail() {
      if (this.dateStart < this.dateEnd) {
        try {
          await this.$store.dispatch("addEmailEvaluation", {
            name: this.name,
            email: this.email,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            emailPortugues: this.emailPortugues,
            emailSpanish: this.emailSpanish,
            emailJapanese: this.emailJapanese,
          });
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: `Adicionado novo email`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs["modal_add"].hide();
          await this.getDays();
        } catch (err) {
          this.$swal({
            icon: "error",
            title: err,
            showConfirmButton: true,
          });
        }
      } else {
        console.log("ERRO");
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Data Inicial não pode ser maior que a Data Final",
          showConfirmButton: true,
        });
      }
    },
    splitDate(date) {
      console.log(date);
      let split = date.split("T");
      return split[0];
    },
    test() {
      console.log("teste");
    },
    async openEmail(
      id,
      dateStart,
      dateEnd,
      email,
      name,
      emailPortugues,
      emailSpanish,
      emailJapanese
    ) {
      this.idEdit = id;
      let dateS = new Date(dateStart);
      let dateE = new Date(dateEnd);
      this.dateStartEdit = new Date(
        dateS.getTime() - dateS.getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      this.dateEndEdit = new Date(
        dateE.getTime() - dateE.getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      this.emailEdit = email;
      this.nameEdit = name;
      this.emailPortuguesEdit = emailPortugues;
      this.emailSpanishEdit = emailSpanish;
      this.emailJapaneseEdit = emailJapanese;
      this.$refs["modal_edit"].show();
    },
    async deleteEmail(id) {
      this.$swal({
        title: "Eliminar o email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Não`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("deleteEmail", {
              id: id,
            });
            this.$swal("Saved!", "", "success");
            await this.getDays();
          } catch (err) {
            alert(err);
          }
        }
      });
    },
  },
  async created() {
    await this.getDays();
  },
  computed: {
    ...mapGetters(["getDaysToEvaluation"]),
    ...mapGetters(["getEmail"]),
  },
};
</script>

<style scoped>
h2 {
  margin-top: 20px;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope="row"] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope="row"] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}
.btn-primary {
  margin-top: 10px;
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-align: center;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>